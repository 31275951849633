import netlifyIdentity from 'netlify-identity-widget';
import React, { createContext, useCallback, useEffect, useState } from "react";

const UserContext = createContext();
let currentUser;
netlifyIdentity.on('init', user => {
  currentUser = user;
});
netlifyIdentity.on('login', user => {
  currentUser = user;
});
netlifyIdentity.on('logout', () => {
  currentUser = {};
  localStorage.setItem(
    "cart",
    JSON.stringify([])
  );
  localStorage.setItem(
    "fav",
    JSON.stringify([])
  );
});
netlifyIdentity.init();
window.netlifyIdentity = netlifyIdentity;

const authenticate = () => {
  netlifyIdentity.open();
}
const signout = () => {
  netlifyIdentity.logout();
}

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(currentUser);
  useEffect(() => {
    if(currentUser){
      netlifyIdentity.refresh();
    }
  }, [])

  const authorizedFetch = useCallback(async (url, options) => {
    if (!user?.token) throw new Error('Cannot authorizedFetch while logged out')

    return fetch(url, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token.access_token}`
      },
    })
  }, [user]);

  const updateUser = (user) => {
    setUser(user)
    localStorage.setItem(
      "gotrue.user",
      JSON.stringify( user ?? {})
    );
  }

  useEffect(() => {
    if(user && user.token) {
      authorizedFetch(`${user.url}/user`, {
        method: 'GET',
      }).then(async resp => {
        const json = await resp.json();
        const newUser = user;
        newUser.user_metadata = json.user_metadata
        updateUser(newUser);
        // toast("Added to Favorites");
      }, reject => {
        console.error(reject);
        // toast("Failed to add to Favorites");
      }).catch(e => {
        console.error(e)
        // toast("Failed to add to Favorites");
      });
    }
  }, [user, authorizedFetch])
  netlifyIdentity.on('login', user => {
    setUser(user);
    window.location.reload();
  });
  netlifyIdentity.on('logout', () => {
    setUser({});
    localStorage.setItem(
      "cart",
      JSON.stringify([])
    );
    localStorage.setItem(
      "fav",
      JSON.stringify([])
    );
    window.location.reload();
  });
  return (
    <UserContext.Provider value={{ currentUser: user, updateUser, authorizedFetch}}>
      {children}
    </UserContext.Provider>
  );
};



export default UserContextProvider;
export { authenticate, signout };
export { UserContext };

