import React, { createContext, useState } from "react";
export const HeaderContext = createContext();

const CATEGORY = { NONE: 0, SHOP: 1, ABOUT: 2, FAVORITES: 3, CART: 4, PROFILE: 5 };
const HeaderContextProvider = ({ children }) => {
    const [category, setCategory] = useState(CATEGORY.NONE);
    return (
        <HeaderContext.Provider value={{ category, setCategory }}>
            {children}
        </HeaderContext.Provider>
    );
};

export default HeaderContextProvider;
export { CATEGORY };
