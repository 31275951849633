const Storage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems?.length > 0 ? cartItems : [])
  );
};

export const sumItems = (cartItems, callback) => {
  Storage(cartItems);
  let itemCount = cartItems?.reduce((total, product) => total + product.quantity, 0) ?? 0;
  let total = cartItems?.reduce((total, product) => total + (product.price * product.quantity), 0) ?? 0;
  callback && callback(cartItems);
  return { itemCount, total };
};

export const CartReducer = (state, action) => {
  let cartItems = state.cartItems ?? [];
  let itemIndex;
  switch (action.type) {
    case "ADD_ITEM":
      itemIndex = cartItems?.findIndex(
        (item) =>
          item.id === action.payload?.id &&
          item.size === action.payload?.size
      );
      if (itemIndex === -1) {
        cartItems.push({
          ...action.payload,
          quantity: 1,
        });
      } else {
        const addQuant =  cartItems[
          itemIndex
        ].quantity
        cartItems[
          itemIndex
        ].quantity = addQuant + 1;
      }
      return {
        ...state,
        ...sumItems(cartItems, state.callback),
        cartItems: [...cartItems],
      };
    case "REMOVE_ITEM":
      let filteredItems = cartItems.filter(
        (item) =>
          !(
            item.id === action.payload.id &&
            item.size === action.payload.size
          )
      )
      return {
        ...state,
        ...sumItems(filteredItems, state.callback),
        cartItems: [...filteredItems],
      };
    case "INCREASE":
      itemIndex = cartItems?.findIndex(
        (item) =>
          item.id === action.payload?.id &&
          item.size === action.payload?.size
      );
      if (itemIndex !== -1) {
        cartItems[
          itemIndex
        ].quantity++;
      }
      return {
        ...state,
        ...sumItems(cartItems, state.callback),
        cartItems: [...cartItems],
      };
    case "DECREASE":
      itemIndex = cartItems?.findIndex(
        (item) =>
          item.id === action.payload?.id &&
          item.size === action.payload?.size
      );
      if (itemIndex !== -1) {
        cartItems[
          itemIndex
        ].quantity--;
      }
      return {
        ...state,
        ...sumItems(cartItems, state.callback),
        cartItems: [...cartItems],
      };
    case "CHECKOUT":
      return {
        cartItems: [],
        checkout: true,
        ...sumItems([], state.callback),
      };
    case "CLEAR":
    default:
      return {
        ...state,
        cartItems: [],
        ...sumItems([], state.callback),
      }
  }
};
