import React, { createContext, useCallback, useContext, useReducer } from "react";
import { CartReducer, sumItems } from "./CartReducer";
import { UserContext } from "./UserContext";

export const CartContext = createContext();

const storage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];
let initialState = {
  cartItems: storage,
  ...sumItems(storage),
  checkout: false,
  callback: (items) => {}
};

const CartContextProvider = ({ children }) => {
  const {currentUser , updateUser, authorizedFetch} = useContext(UserContext);

  const updateCallback = useCallback((items) =>{
    if(currentUser && currentUser.token){
      const user_metadata = {
        data: {
          orders: items
        }
      }
      authorizedFetch(`${currentUser.url}/user`, {
        method: 'PUT',
        body: JSON.stringify(user_metadata)
      }).then(async resp => {
        const json = await resp.json();
        const newUser = currentUser;
        newUser.user_metadata.orders = json.user_metadata.orders
        updateUser(newUser)
      }, reject => {
        console.error(reject);
      }).catch(e => {
        console.error(e)
      });
    }
  }, [currentUser, updateUser, authorizedFetch]);

  const initState = () => {
    if(currentUser && currentUser.user_metadata) {
      initialState = {
        cartItems: currentUser.user_metadata?.orders,
        ...sumItems(currentUser.user_metadata?.orders),
        checkout: false,
        user: currentUser,
        callback: updateCallback
      };
    }
    return initialState;
  }
  const [state, dispatch] = useReducer(CartReducer, initialState, initState);

  const isInCart = (product) => {
    return state.cartItems.find((item) => item.id === product.id) !== undefined;
  };

  const increase = (payload) => {
    dispatch({ type: "INCREASE", payload });
  };

  const decrease = (payload) => {
    dispatch({ type: "DECREASE", payload });
  };

  const addProduct = (payload) => {
    dispatch({ type: "ADD_ITEM", payload });
  };

  const removeProduct = (payload) => {
    dispatch({ type: "REMOVE_ITEM", payload });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR" });
  };

  const handleCheckout = () => {
    dispatch({ type: "CHECKOUT" });
  };

  const startCheckoutSession = (body, success, reject) => {
    return fetch(`https://atelierleyla.ca/.netlify/functions/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then(async resp => {
      const json = await resp.json();
      window.location.href = json;
      success && success();
    }, reject => {
      reject && reject();
    }).catch(e => {
      console.error(e)
      reject && reject();
    });
  }

  const isValidCheckoutSession = (body, success, reject) => {
    return fetch(`https://atelierleyla.ca/.netlify/functions/get-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then(async resp => {
      const json = await resp.json();
      success && success(json);
    }, reject => {
      reject && reject();
    }).catch(e => {
      console.error(e)
      reject && reject();
    });
  }

  const contextValues = {
    increase,
    decrease,
    clearCart,
    addProduct,
    removeProduct,
    handleCheckout,
    isInCart,
    startCheckoutSession,
    isValidCheckoutSession,
    ...state,
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
