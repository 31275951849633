import React, { useContext, useEffect } from "react";
import { FaArrowRight, FaHeart, FaShoppingCart, FaUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {ReactComponent as LOGOSVG}  from "../../res/logo-inverted.svg";
import "./Header.css";
import Cart from "../../pages/Cart/Cart";
import { authenticate, signout, UserContext } from "../../context/UserContext";
import Fav from "../../pages/Fav/Fav";
import { CartContext } from "../../context/CartContext";
import { CATEGORY, HeaderContext } from "../../context/HeaderContext";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
  const {category, setCategory} = useContext(HeaderContext)
  const {currentUser} = useContext(UserContext);
  const {total/*, cartItems, itemCount, startCheckoutSession*/} = useContext(CartContext);
  const onShopButtonClicked = (ev) => setCategory(CATEGORY.SHOP);
  const onFavButtonClicked = (ev) => setCategory(CATEGORY.FAVORITES);
  const onCartButtonClicked = (ev) => setCategory(CATEGORY.CART);
  const onProfileButtonClicked = (ev) => { currentUser?.user_metadata ? setCategory(CATEGORY.PROFILE) : authenticate();}
  const onDropdownClosed = (ev) => setCategory(CATEGORY.NONE);
  const navigate = useNavigate();

  const handleClick = (ev) => {
    const classes = ev?.target?.offsetParent?.classList[0];
    if (classes && !classes?.includes("Header-nav-dropdown") 
      && !classes?.includes("Header-nav-dropdown-right") 
      && !classes?.includes("Header-header")
      && category !== CATEGORY.NONE) {
      onDropdownClosed();
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };
  });

  let shopDropdown = (
    <div
      id="Header-Nav-Dropdown"
      className="Header-nav-dropdown"
      onMouseLeave={onDropdownClosed}
    >
      <Link
        className="nav-link-item"
        onClick={() => onDropdownClosed()}
        to="/shop"
      >
        All
      </Link>
      <Link
        className="nav-link-item"
        onClick={() => onDropdownClosed()}
        to="/tops"
      >
        Tops
      </Link>
      <Link
        className="nav-link-item"
        onClick={() => onDropdownClosed()}
        to="/dresses"
      >
        Dresses
      </Link>
      <Link
        className="nav-link-item"
        onClick={() => onDropdownClosed()}
        to="/bottoms"
      >
        Bottoms
      </Link>
    </div>);
  let aboutDropdown = (
    <div
      id="Header-Nav-Dropdown"
      className="Header-nav-dropdown"
      onMouseLeave={() => onDropdownClosed()}
    >
      <Link
        className="nav-link-item"
        onClick={() => onDropdownClosed()}
        to="/"
      >
        Home
      </Link>
      <Link
        className="nav-link-item"
        onClick={() => onDropdownClosed()}
        to="/about"
      >
        About Us
      </Link>
      <Link
        className="nav-link-item"
        onClick={() => onDropdownClosed()}
        to="/sustainability"
      >
        Sustainability
      </Link>
      <Link
        className="nav-link-item"
        onClick={() => onDropdownClosed()}
        to="/care"
      >
        Care
      </Link>
      <Link
        className="nav-link-item"
        onClick={() => onDropdownClosed()}
        to="/policy"
      >
        Policies
      </Link>
      <Link
        className="nav-link-item"
        onClick={() => onDropdownClosed()}
        to="/contact"
      >
        Contact Us
      </Link>
    </div>);
  let favDropdown = (
    <div
      id="Header-Nav-Dropdown"
      className="Header-nav-dropdown-right medium-flyout"
      onMouseLeave={() => onDropdownClosed()}
    >
      <Fav />
      <button className="Header-Fav-button" onClick={() => { onDropdownClosed();  navigate("/favorites")}}>
        View all favorites <FaArrowRight />
      </button>
    </div>);
  let cartDropdown = window.location.pathname === "/cart" ? <></> : (<div
    id="Header-Nav-Dropdown"
    className={"Header-nav-dropdown-right"}
    onMouseLeave={() => onDropdownClosed()}
  >
    <Cart />
    <div className="Cart-subtotal-container" style={{marginTop:5}}>
      <div>Subtotal</div>
      <div>{(total - 0).toFixed(2)}</div>
    </div>
    <button className="Header-Cart-button" onClick={() => { /*itemCount > 0 ? startCheckoutSession(cartItems): */ toast("Coming Soon!"); onDropdownClosed()}}>
      Buy now <FaArrowRight />
    </button>

  </div>);
  let profileDropdown = (<div
    id="Header-Nav-Dropdown"
    className="Header-nav-dropdown-right small-flyout"
    onMouseLeave={onDropdownClosed}
  >
    {!currentUser ? <Link onClick={authenticate}>Login</Link> : (
      <>
        <Link
          className="nav-link-item"
          onClick={() => onDropdownClosed()}
          to="/profile"
        >
          Profile
        </Link>
        <Link
          className="nav-link-item"
          onClick={() => onDropdownClosed()}
          to="/favorites"
        >
          Favorites
        </Link>
        <Link
          className="nav-link-item"
          onClick={() => onDropdownClosed()}
          to="/orders"
        >
          Orders
        </Link>
        <Link
          className="nav-link-item"
          onClick={() => onDropdownClosed()}
          to="/addresses"
        >
          Addresses
        </Link>
        <Link
          className="nav-link-item"
          onClick={() => onDropdownClosed()}
          to="/payment"
        >
          Payment
        </Link>
        <Link
          className="nav-link-item"
          onClick={() => signout()}
          to="/"
        >
          Logout
        </Link>
    </>
    )}
  </div>);

  let dropdownEl;
  switch (category) {
    case CATEGORY.SHOP:
      dropdownEl = shopDropdown;
      break;
    case CATEGORY.ABOUT:
      dropdownEl = aboutDropdown;
      break;
    case CATEGORY.FAVORITES:
      dropdownEl = favDropdown;
      break;
    case CATEGORY.CART:
      dropdownEl = cartDropdown;
      break;
    case CATEGORY.PROFILE:
      dropdownEl = profileDropdown;
      break;
    case CATEGORY.NONE:
      dropdownEl = <></>;
      break;
    default:
      dropdownEl = <></>;
      break;
  }

  return (
    <header className="Header-header" onMouseLeave={() => onDropdownClosed()}>
     <LOGOSVG  className="Header-logo" alt="website logo" onClick={() => navigate("/")} onMouseEnter={() => setCategory(CATEGORY.ABOUT)} />
      <div
        className="Header-nav-item margin-left"
        onClick={onShopButtonClicked}
        onMouseEnter={() => setCategory(CATEGORY.SHOP)}
      >
        Shop
      </div>
      <div className="Header-buttons" >
        <div className="Header-favorites" onClick={onFavButtonClicked} onMouseEnter={() => setCategory(CATEGORY.FAVORITES)}>
          <FaHeart name="Favorites" />
        </div>
        <div className="Header-cart" to="/cart" onClick={onCartButtonClicked} onMouseEnter={() => setCategory(CATEGORY.CART)}>
          <FaShoppingCart name="Cart" />
        </div>
        <div className="Header-account" onClick={onProfileButtonClicked} onMouseEnter={() => setCategory(CATEGORY.PROFILE)}>
          <FaUser name="Profile" />
        </div>
      </div>
      {dropdownEl}
    </header>
  );
};

export default Header;
