import React, { useContext } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FavContext } from "../../context/FavContext";
import "./Grid.css";

const Grid = (props) => {
  const { addProduct , isInFavs, removeProduct } = useContext(FavContext);
  const items = props.items;
  const gridClassName = "grid-container " + (!!props.small ? "small-grid" : "");
  const gridItemClassName = "grid-item " + (!!props.small ? "small-grid" : "");

  const elements = items.map((item, index, array) => {
    const itemEl = (
      <div className="grid-item-container" key={item.url + item.color}>
        <Link to={"/item/" + item.url + "?color=" + item.color}>
          <img
            className={gridItemClassName}
            decoding="async"
            key={item.id}
            src={item.images[0]}
            width={item.images[0]?.width ?? 800}
            height={item.images[0]?.height ?? 1200}
            loading={index < 4 ? "eager" : "lazy"}
            alt={item.shortName}
          />
        </Link>
        <div className="grid-item-footer-container">
          <Link to={"/item/" + item.url + "?color=" + item.color}>
            <div className="grid-item-footer-container grid-item-text-container">
              <div className="grid-item-text grid-item-name">{item.shortName}</div>
              <div className="grid-item-text grid-item-price" noindex="true" data-nosnippet>{item.price}</div>
            </div>
          </Link>
            {isInFavs(item.id) ? <FaHeart className="grid-item-button" onClick={() => removeProduct(item)} /> : <FaRegHeart className="grid-item-button" onClick={() => {addProduct(item)}} />}
          </div>
      </div>);
    return itemEl;
  });

  return <div className={gridClassName}>{elements}</div>;
};

export default Grid;
