import React, { useContext } from "react";
import { FaHeart } from "react-icons/fa";
import { useNavigate } from "react-router";
import Grid from "../../components/Grid/Grid";
import { FavContext } from "../../context/FavContext";
import "./Fav.css";

const FavItem = (props) => {
  const item = props.item;
  const { removeProduct } = useContext(FavContext);
  const onRemove = () => {
    removeProduct(item);
  };
  return (
    <div id="FavItem-container" className="FavItem-container">
      <img className="FavItem-image" src={props.item.images[0]} alt={props.item.shortName + ' Image'}></img>
      <div className="FavItem-content">
        <div className="FavItem-name"><b>Name:</b> {props.item.shortName}</div>
        <div className="FavItem-price"><b>Price:</b> {props.item.price}</div>
        <div className="FavItem-remove" onClick={onRemove}>
          ⓧ Remove
        </div>
      </div>
    </div>
  );
};

const Fav = (props) => {
  const cartCtx = useContext(FavContext);
  const navigate = useNavigate();
  if(!cartCtx.favItems || cartCtx.favItems.length === 0) {
    return (
      <div id="Fav-container" className="Fav-container">
        {props.page ? <h3>No Favorites</h3> : <></>}
        <div id="Fav-empty-container" className="Fav-empty-container">
          <FaHeart onClick={() => navigate("/")} style={{ height: "25px", width: "25px", cursor:"pointer"}} />
          <p className="Fav-store-button" onClick={() => navigate("/")}>Browse the store</p>
        </div>
      </div>)
  }

  return (
    <div className="Fav-container" style={{padding:!props.page?0:25}}>
      {props.page ? <h3 className="Fav-title">Favorites</h3> : <></>}
      <div className="Fav-Items">
        <Grid items={cartCtx.favItems} small={props.page ? false : true}/>
      </div>
    </div>
  );
};

export default Fav;
export { FavItem };
