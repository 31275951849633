import React, { useContext } from "react";
import { FaMinusCircle, FaPlusCircle, FaStore } from "react-icons/fa";
import { TbCircleX } from "react-icons/tb";
import { useNavigate } from "react-router";
import { CartContext } from "../../context/CartContext";
import "./Cart.css";

const CartItem = (props) => {
  const item = props.item;
  return (
    <div id="CartItem-container" className="CartItem-container">
      <img className="CartItem-image" src={item.images[0]} alt={item.shortName + ' Image'}></img>
      <div className="CartItem-content">
        <div className="CartItem-name"><b>Name:</b> {item.shortName}</div>
        <div className="CartItem-size"><b>Size:</b> {item.size}</div>
        <div className="CartItem-price"><b>Price:</b> {item.price}</div>
        <div className="CartItem-quantity"><b>Quantity:</b> {item.quantity}</div>
        <div className="CartItem-bottom-buttons">

          <div className="CartItem-buttons-left">
            <FaMinusCircle onClick={ item.quantity === 1 ? props.removeProduct : props.decrease}/>
            <FaPlusCircle onClick={props.increase}/>
          </div>
          <TbCircleX className="CartItem-buttons-right" onClick={props.removeProduct}/>
        </div>
      </div>
    </div>
  );
};

const Cart = (props) => {
  const {cartItems, total, increase, decrease ,removeProduct } = useContext(CartContext);
  const navigate = useNavigate();

  if (!cartItems || cartItems.length === 0) {
    return (
      <div id="Cart-container" className="Cart-container">
        {props.page ? <h3>Empty Cart</h3> : <></>}
        <div id="Cart-empty-container" className="Cart-empty-container">
          <FaStore onClick={() => navigate("/")} style={{ height: "25px", width: "25px", cursor: "pointer" }} />
          <p className="Cart-store-button" onClick={() => navigate("/")}>Browse the store</p>
        </div>
      </div>)
  }

  const renderBox =
    props.page ?
      (<div className="Cart-summary-box">
        <div className="Cart-price"><b>Price:</b> {(total - 0).toFixed(2)}</div>
        <div className="Cart-shipping-container">
          <b>Shipping: </b> Free Shipping
        </div>
        <div className="Cart-total"><b>Current Total:</b> {(total - 0).toFixed(2)}</div>
      </div>)
      : <></>

      
  return (
    <div className="Cart-container" style={{padding:!props.page?0:25}}>
      {props.page ? <h3 className="Cart-title">Cart</h3> : <></>}
      {renderBox}
      <div className="Cart-Items">
        {cartItems.map((element) => {
          return <CartItem key={"Cart" + element.id+element.size} item={element} increase={() => increase(element)} decrease={() => decrease(element)} removeProduct={() => removeProduct(element)}/>;
        })}
      </div>
    </div>
  );
};

export default Cart;
export { CartItem };
