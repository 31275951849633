import React, { createContext, useCallback, useContext, useReducer } from "react";
import { FavReducer, sumItems } from "./FavReducer";
import { UserContext } from "./UserContext";

export const FavContext = createContext();

const storage = localStorage.getItem("fav")
  ? JSON.parse(localStorage.getItem("fav"))
  : [];

let initialState = {
  favItems: storage,
  ...sumItems(storage),
  checkout: false,
  callback: (items) => {}
};

const FavContextProvider = ({ children }) => {
  const {currentUser, updateUser, authorizedFetch} = useContext(UserContext);
  const updateCallback = useCallback((items) =>{
    if(currentUser && currentUser.token){
      const user_metadata = {
        data: {
          favs: items
        }
      }
      authorizedFetch(`${currentUser.url}/user`, {
        method: 'PUT',
        body: JSON.stringify(user_metadata)
      }).then(async resp => {
        const json = await resp.json();
        const newUser = currentUser;
        newUser.user_metadata.favs = json.user_metadata.favs
        updateUser(newUser)
      }, reject => {
        console.error("Something went wrong updating user data")
      }).catch(e => {
        console.error(e)
      });
    }
  }, [currentUser, updateUser, authorizedFetch]);

  const initState = () => {
    if(currentUser && currentUser.user_metadata) {
      initialState = {
        favItems: currentUser.user_metadata?.favs,
        ...sumItems(currentUser.user_metadata?.favs),
        checkout: false,
        callback: updateCallback
      };
      
    }
    return initialState;
  }
  const [state, dispatch] = useReducer(FavReducer, initialState, initState);

  const isInFavs = (id) => {
    return state.favItems?.find((item) => item.id === id) ?? false;
  };

  const addProduct = (payload) => {
    dispatch({ type: "ADD_ITEM", payload });
  };

  const removeProduct = (payload) => {
    dispatch({ type: "REMOVE_ITEM", payload });
  };

  const clearFav = () => {
    dispatch({ type: "CLEAR" });
  };

  const contextValues = {
    removeProduct,
    addProduct,
    clearFav,
    isInFavs,
    ...state,
  };

  return (
    <FavContext.Provider value={contextValues}>
      {children}
    </FavContext.Provider>
  );
};

export default FavContextProvider;
