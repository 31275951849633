import JasmineTop1 from "../res/models/IMG_0979.webp";
import JasmineTop2 from "../res/models/IMG_1010.webp";
import JasmineTop3 from "../res/models/IMG_1012.webp";
import JasmineTop4 from "../res/models/IMG_1014.webp";
import MinaDress1 from "../res/models/IMG_1020.webp";
import MinaDress2 from "../res/models/IMG_1037.webp";
import MinaDress3 from "../res/models/IMG_1042.webp";
import MinaDress4 from "../res/models/IMG_1052.webp";
import DaphneDressPink1 from "../res/models/IMG_0683.webp";
import DaphneDressPink2 from "../res/models/IMG_0685.webp";
import DaphneDressPink3 from "../res/models/IMG_0688.webp";
import DaphneDressIvory1 from "../res/models/IMG_0708.webp";
import DaphneDressIvory2 from "../res/models/IMG_0713.webp";
import DaphneDressIvory3 from "../res/models/IMG_0729.webp";
import WisteriaDressBlack1 from "../res/models/IMG_0751.webp";
import WisteriaDressBlack2 from "../res/models/IMG_0761.webp";
import WisteriaDressBlack3 from "../res/models/IMG_0776.webp";
import { default as LiliumSkirt1, default as TrilliumTopIvory1 } from "../res/models/IMG_0787.webp";
import { default as LiliumSkirt2, default as TrilliumTopIvory2 } from "../res/models/IMG_0798.webp";
import { default as LiliumSkirt3, default as TrilliumTopIvory3 } from "../res/models/IMG_0814.webp";
import { default as ZinniaBrownTop1, default as ZinniaPantBrown1 } from "../res/models/IMG_0831.webp";
import ZinniaPantBrown2 from "../res/models/IMG_0862.webp";
import PeonyDress1 from "../res/models/IMG_0873.webp";
import PeonyDress2 from "../res/models/IMG_0876.webp";
import PeonyDress3 from "../res/models/IMG_0886.webp";
import DahliaDress1 from "../res/models/IMG_0900.webp";
import DahliaDress2 from "../res/models/IMG_0903.webp";
import DahliaDress3 from "../res/models/IMG_0912.webp";
import DahliaDress4 from "../res/models/IMG_0924.webp";
import DahliaDress5 from "../res/models/IMG_0930.webp";
import PoppyDress1 from "../res/models/IMG_0931.webp";
import PoppyDress2 from "../res/models/IMG_0935.webp";
import PoppyDress3 from "../res/models/IMG_0957.webp";
import PoppyDress4 from "../res/models/IMG_0961.webp";
import { default as JasmineTop5, default as JasmineSkirt1 } from "../res/models/IMG_0984.webp";
import { default as JasmineTop6, default as JasmineSkirt2 } from "../res/models/IMG_0988.webp";
import { default as JasmineTop7, default as JasmineSkirt3 } from "../res/models/IMG_0999.webp";
import { default as JasmineTop8, default as JasmineSkirt4 } from "../res/models/IMG_1002.webp";


export const dummyProducts = [
  {
    id: "DS22001IV",
    url: "daphne-dress",
    shortName: "Daphne Dress - Ivory",
    name: "Daphne Dress - Linen, A-line midi dress",
    description:
      "This is a square-neck A-line dress with inseam pockets, adjustable self-tie, and a front button closure.",
    color: "Ivory",
    price: 195,
    fabric: "Linen",
    category: "dress",
    images: [
      DaphneDressIvory1,
      DaphneDressIvory2,
      DaphneDressIvory3,
    ],
  },
  {
    id: "DS22001PI",
    url: "daphne-dress",
    shortName: "Daphne Dress - Pink",
    name: "Daphne Dress - Linen, A-line midi dress",
    description:
      "This is a square-neck A-line dress with inseam pockets, adjustable self-tie, and a front button closure.",
    color: "Pink",
    price: 195,
    fabric: "Linen",
    category: "dress",
    images: [
      DaphneDressPink1,
      DaphneDressPink2,
      DaphneDressPink3,
    ],
  },
  {
    id: "DS22002BK",
    url: "wisteria-dress",
    shortName: "Wisteria Dress",
    name: "Wisteria Dress - A-line midi dress",
    description:
      "This is a classy A-line dress with flounced hem for an added flare. It has a square-neck with shoulder straps that are adjustable self-tie.",
    color: "Black",
    price: 195,
    fabric: "Linen",
    category: "dress",
    images: [
      WisteriaDressBlack1,
      WisteriaDressBlack2,
      WisteriaDressBlack3,
    ],
  },
  {
    id: "DS22003BL",
    url: "peony-dress",
    shortName: "Peony Dress",
    name: "Peony Dress | A-line Mini Dress",
    description:
      "Ruched bust dress with an a-line skirt and inseam pockets with adjustable shoulder straps.",
    color: "Blush",
    price: 195,
    fabric: "Tencel",
    category: "dress",
    images: [PeonyDress1, PeonyDress2, PeonyDress3],
  },
  {
    id: "DS22004PE",
    url: "mina-dress",
    shortName: "Mina Dress",
    name: "Mina Dress | A-line Puff Sleeved Midi Dress",
    description:
      "A-line dress with puff sleeves, inseam pockets and an invisible zipper in the center back.",
    color: "Pearl",
    price: 175,
    fabric: "Tencel",
    category: "dress",
    images: [
      MinaDress1,
      MinaDress2,
      MinaDress3,
      MinaDress4,
    ],
  },
  {
    id: "DS22005BK",
    url: "dahlia-dress",
    shortName: "Dahlia Dress",
    name: "Dahlia Dress | A-line midi dress",
    description: "Midi, a-line dress with inseam pockets and adjustable straps that can be tied in multiple ways.",
    color: "Black",
    price: 155,
    fabric: "Tencel",
    category: "dress",
    images: [
      DahliaDress1,
      DahliaDress2,
      DahliaDress3,
      DahliaDress4,
      DahliaDress5,
    ],
  },
  {
    id: "DS22006BK",
    url: "poppy-dress",
    shortName: "Poppy Dress",
    name: "Poppy Dress - Tiered Maxi Dress",
    description:
      "Maxi dress with gathered tiers. Inseam Pockets and adjustable shoulder straps",
    color: "Black",
    price: 250,
    fabric: "Tencel",
    category: "dress",
    images: [
      PoppyDress1,
      PoppyDress2,
      PoppyDress3,
      PoppyDress4,
    ],
  },
  {
    id: "TP22001BR",
    url: "zinnia-top",
    shortName: "Zinnia Top",
    name: "Zinnia Top - Cropped, square-neck top",
    description:
      "Cropped top with buttons on the centre front with french darts. This top also has adjustable self-tie shoulder straps which adds a more feminine detail.",
    color: "Brown",
    price: 100,
    fabric: "Linen",
    category: "top",
    images: [
      ZinniaBrownTop1,
    ],
  },
  {
    id: "TP22002IV",
    url: "trillium-top",
    shortName: "Trillium Top",
    name: "Trillium Top - Cropped, puff sleeved top",
    description:
      "Cropped top with puff sleeves with adjustable ties. This top has princess seams for shaping and a button closure on the centre back.",
    color: "Ivory",
    price: 150,
    fabric: "Cotton",
    category: "top",
    images: [
      TrilliumTopIvory1,
      TrilliumTopIvory2,
      TrilliumTopIvory3,
    ],
  },
  {
    id: "TP22003PE",
    url: "jasmine-top",
    shortName: "Jasmine Top",
    name: "Jasmine Top | Fitted, puff sleeved top",
    description: "A fitted top with princess seams and puff sleeves. This top has a invisible zipper in the center back.",
    color: "Pearl",
    price: 100,
    fabric: "Tencel",
    category: "top",
    images: [
      JasmineTop1,
      JasmineTop2,
      JasmineTop3,
      JasmineTop4,
      JasmineTop5,
      JasmineTop6,
      JasmineTop7,
      JasmineTop8
    ],
  },
  {
    id: "PT22001BR",
    url: "zinnia-pant",
    shortName: "Zinnia Pant",
    name: "Zinnia Pant - High-waisted linen pants",
    description:
      "High-waisted, wide-leg linen pants with a style-line detail. Made with 100% linen for a comfortable summer pant.",
    color: "Brown",
    price: 185,
    fabric: "Linen",
    category: "bottom",
    images: [
      ZinniaPantBrown2,
      ZinniaPantBrown1,
    ],
  },
  {
    id: "SK22001PE",
    url: "lilium-skirt",
    shortName: "Lilium Skirt",
    name: "Lilium Skirt - High-waisted linen skirt",
    description:
      "High-waisted, A-line skirt with a flounce on the hem for an added feminine detail. The skirt has an invisible zipper at the side seam.",
    color: "Pearl",
    price: 125,
    fabric: "Tencel",
    category: "bottom",
    images: [
      JasmineSkirt1,
      JasmineSkirt2,
      JasmineSkirt3,
      JasmineSkirt4
    ],
  },
  {
    id: "SK22001IV",
    url: "lilium-skirt",
    shortName: "Lilium Skirt",
    name: "Lilium Skirt - High-waisted linen skirt",
    description:
      "High-waisted, A-line skirt with a flounce on the hem for an added feminine detail. The skirt has an invisible zipper at the side seam.",
    color: "Ivory",
    price: 125,
    fabric: "Linen",
    category: "bottom",
    images: [
      LiliumSkirt2,
      LiliumSkirt1,
      LiliumSkirt3,
    ],
  },
];
