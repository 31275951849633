import React, { Suspense } from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CartContextProvider from "./context/CartContext";
import ProductsContextProvider from "./context/ProductsContext";
import reportWebVitals from "./reportWebVitals";

import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import FavContextProvider from "./context/FavContext";
import HeaderContextProvider from "./context/HeaderContext";
import UserContextProvider from "./context/UserContext";
import Fav from "./pages/Fav/Fav";

const Home = React.lazy(() => import("./pages/Home/Home"));
const Bottoms = React.lazy(() => import("./pages/Shop/Categories/Bottoms/Bottoms"));
const Consignment = React.lazy(() => import("./pages/Shop/Categories/Consignment/Consignment"));
const Dresses = React.lazy(() => import("./pages/Shop/Categories/Dresses/Dresses"));
const Prints = React.lazy(() => import("./pages/Shop/Categories/Prints/Prints"));
const Tops = React.lazy(() => import("./pages/Shop/Categories/Tops/Tops"));
const Shop = React.lazy(() => import("./pages/Shop/Shop"));
const Success = React.lazy(() => import("./pages/Success/Success"));
const Item = React.lazy(() => import('./pages/Item/Item'));
const About = React.lazy(() => import("./pages/About/About"));
const Blog = React.lazy(() => import("./pages/Blog/Blog"));
const Care = React.lazy(() => import("./pages/Care/Care"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const Policy = React.lazy(() => import("./pages/Policy/Policy"));
const Sustainability = React.lazy(() => import("./pages/Sustainability/Sustainability"))

document.title = "Atelier Leyla"
const rootElement = document.getElementById("root");
function App() {
  return (<Suspense fallback={<></>}>
    <Router>
      <Header></Header>
      <div className="App" /*style={{ display: ready ? "block" : "none" }}*/>
        <ToastContainer position="bottom-center" pauseOnFocusLoss={false} pauseOnHover={true} theme="dark"/>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />}  />
          <Route path="/blog" element={<Blog />} />
          <Route path="/care" element={<Care />} />
          <Route path="/success" element={<Success />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/item/:id" element={<Item />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/dresses" element={<Dresses />} />
          <Route path="/tops" element={<Tops />} />
          <Route path="/bottoms" element={<Bottoms />} />
          <Route path="/prints" element={<Prints />} />
          <Route path="/consignment" element={<Consignment />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/favorites" element={<Fav page="true" />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
      <Footer></Footer>
    </Router>
  </Suspense>)
}

const renderContent = (
    <UserContextProvider>
      <ProductsContextProvider>
        <HeaderContextProvider>
          <FavContextProvider>
            <CartContextProvider>
              <App />
            </CartContextProvider>
          </FavContextProvider>
        </HeaderContextProvider>
      </ProductsContextProvider>
    </UserContextProvider>)

if (rootElement.hasChildNodes()) {
  hydrate(renderContent, document.getElementById("root"));
} else {
  render(renderContent, document.getElementById("root"));
}

function sendToAnalytics({ name, id, delta, value, attribution }) {
  const eventParams = {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.
  };

  switch (name) {
    case 'CLS':
      eventParams.debug_target = attribution?.largestShiftTarget;
      break;
    case 'FID':
      eventParams.debug_target = attribution?.eventTarget;
      break;
    case 'INP':
      eventParams.debug_target = attribution?.eventTarget;
      eventParams.inp_target = attribution?.eventTarget;
      eventParams.inp_type = attribution?.eventType;
      eventParams.inp_time = attribution?.eventTime;
      eventParams.inp_load_state = attribution?.loadState;
      break;
    case 'LCP':
      eventParams.debug_target = attribution?.element;
      break;
    default:
      eventParams.debug_target = attribution?.element;
      break;
  }
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  window?.gtag && window.gtag('event', name, eventParams);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToAnalytics);
