const Storage = (favItems) => {
  localStorage.setItem(
    "fav",
    JSON.stringify(favItems?.length > 0 ? favItems : [])
  );
};

export const sumItems = (favItems, callback) => {
  Storage(favItems);
  let itemCount = favItems?.length ?? 0
  callback && callback(favItems);
  return { itemCount };
};

export const FavReducer = (state, action) => {
  let favItems = state.favItems ?? [];
  switch (action.type) {
    case "ADD_ITEM":
      if (
        !favItems?.find(
          (item) => item.id === action.payload.id)
      ) {
        favItems?.push(action.payload);
      } else {
        //Do Nothing
      }

      return {
        ...state,
        ...sumItems(favItems, state.callback),
        favItems: [...favItems],
      };
    case "REMOVE_ITEM":
      const filteredItems = 
      favItems.filter(
        (item) =>
          !(
            item.id === action.payload.id
          )
      );
      return {
        ...state,
        ...sumItems(filteredItems, state.callback),
        favItems: [...filteredItems],
      };
    case "CHECKOUT":
      return {
        favItems: [],
        checkout: true,
        ...sumItems([], state.callback),
      };
    case "CLEAR":
      return {
        ...state,
        favItems: [],
        ...sumItems([], state.callback),
      };
    default:
      return state;
  }
};
